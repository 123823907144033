import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

// import app components
import Textarea from "components/textarea"
import Spacer from "components/spacer"
import SidebarContent from "components/sidebarContent"
import LinkBoxes from "components/footer/LinkBoxes"
import { getUrlPath } from "utils/getUrlPath"

export default function SingleArchitect(props) {
  const {
    data: {
      wpArchitect: { title, uri, content, acf }
    }
  } = props

  return (
    <>
      <SidebarContent image={null} title={title} slug={getUrlPath(uri)} hideSidebarOnMobile>
        {acf?.image?.sourceUrl && (
          <ImageContainer>
            <img src={acf.image.sourceUrl} alt="Logo" />
          </ImageContainer>
        )}

        <Spacer pb={30}>
          <StyledTextarea content={content} />
        </Spacer>
      </SidebarContent>

      <LinkBoxes />
    </>
  )
}

const ImageContainer = styled.div`
  width: 100%;
  height: 120px;
  margin-bottom: 30px;
  padding: 20px;
  position: relative;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 640px) {
    max-width: 264px;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }
`

const StyledTextarea = styled(Textarea)`
  clear: unset;
`

export const CollectionQuery = graphql`
  query SingleArchitect($id: String!) {
    wpArchitect(id: { eq: $id }) {
      id
      title
      uri
      content
      acf {
        image {
          sourceUrl
        }
      }
    }
  }
`
